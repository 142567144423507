const ncms = [];

let raw = JSON.parse('{"Data_Ultima_Atualizacao_NCM":"01/01/2023","Ato":"Resolução Camex nº 413/2022","Nomenclaturas":[{"Codigo":"01","Descricao":"Animais vivos.","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"01.01","Descricao":"Cavalos, asininos e muares, vivos.","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"0101.2","Descricao":"- Cavalos:","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"0101.21.00","Descricao":"-- Reprodutores de raça pura","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"0101.29.00","Descricao":"-- Outros","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"0101.30.00","Descricao":"- Asininos","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"0101.90.00","Descricao":"- Outros","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"01.02","Descricao":"Animais vivos da espécie bovina.","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"0102.2","Descricao":"- Bovinos domésticos:","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"0102.21","Descricao":"-- Reprodutores de raça pura","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"},{"Codigo":"0102.21.10","Descricao":"Prenhes ou com cria ao pé","Data_Inicio":"01/04/2022","Data_Fim":"31/12/9999","Tipo_Ato":"Res Camex","Numero_Ato":"000272","Ano_Ato":"2021"}]}');

for (let key in raw.Nomenclaturas) {
    ncms.push({
        id: raw.Nomenclaturas[key].Codigo,
        text: raw.Nomenclaturas[key].Codigo + " " + raw.Nomenclaturas[key].Descricao
    });
}

export default ncms;